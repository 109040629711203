import { ArrowBackIos, Clear } from '@mui/icons-material';
import { Box, Breadcrumbs, FilledInput, FormControl, IconButton, InputAdornment, InputLabel, Link, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import Dashboard from './Dashboard';
import { IReportData } from '../shared/interfaces';
import { MatrixService } from "../services/matrixservice";
import { Page } from './layout/Page';
import ReactDOM from 'react-dom';
import ReportCards from './ReportCards';
import { SpinnerContainer } from "./shared/SpinnerContainer";

const matrixService = new MatrixService();

const MAX_ITEMS_BEFORE = 2;
const MAX_ITEMS_AFTER = 8;
export type Props = {
  reportTypes: string[]
}

//const Reports: React.FC<{reportTypes: string[]}> = ({ reportTypes }) => {
const Reports: React.FC = () => {

  const theme = useTheme();
  const mdBreakAfter = useMediaQuery(theme.breakpoints.up("md"));
  const [reportDisplay, setReportDisplay] = useState(false);
  const [currentReport, setCurrentReport] = useState<IReportData>({ReportName: "No Report", DashboardId:"none", ReportType:"bookings", ReportAccount:"bookings", ImageFile: "thumb-0", RLS:false});
  const [reportDefs, setReportDefs] = useState<IReportData[]>([]);
  const [filteredReportDefs, setFilteredReportDefs] = useState<IReportData[]>([])
  const [loading, setLoading] = useState(false);
  const [reportFound, setReportFound] = useState(false);
  const [noDashes, setNoDashes] = useState(false);
  const [dashCallMade, setDashCallMade] = useState(false);
  const [filterExpression, setFilterExpression] = useState('');
  const reportPath = window.location.pathname.toString().replace('/','');

  const showReport = useCallback((dash:IReportData) => {
    setCurrentReport(dash)
    setReportDisplay(true);
    const pathToSet = sanitiseReportName(dash.ReportName);
    window.history.pushState(null, document.title, `/${pathToSet}`);
  },[])

  const closeReport = () => {
    setLoading(true);
    setReportDisplay(false);
    let container = document.querySelector('#embeddingContainer');
    if(container) {
      container.innerHTML = '';
    }
    getReportDefs();
    window.location.pathname = '';
  }

  const getReportDefs = async () => {

      if(!dashCallMade) {
        const reportData = await matrixService.getReportData();

        if(reportData.length > 0) {

          reportData.sort((a,b)=>{ 
            const dashA = a.ReportName.toUpperCase();
            const dashB = b.ReportName.toUpperCase();
  
            let comparison = 0;
  
            if(dashA > dashB) {
              comparison = 1;
            } else if (dashA < dashB) {
              comparison = -1;
            }
  
            return comparison;
  
          });

          setReportDefs(reportData);
          setFilteredReportDefs(reportData);
          checkReportPath(reportData);
          setNoDashes(false);
          setDashCallMade(true);
        } else { 
          setNoDashes(true);
          setDashCallMade(true);
        }
      }
      else{
        if (reportDefs.length === 0) {
          setNoDashes(true);
          setReportDisplay(false);
          setLoading(false);
        }
      }
  }
  
  const renderBreadcrumbNav = ():JSX.Element => {

    return (
      <div className="tw-bg-white tw-flex tw-items-center tw-py-1" style={{minHeight: "55px", boxShadow: "0 2px 2px rgb(0,0,0,0.2)"}}>
        <div className="tw-flex tw-items-start tw-w-full tw-pl-4 tw-mx-auto tw-pt-1">
          <IconButton aria-label="back" className="tw-text-gray1 tw-relative" style={{bottom: "4px", padding: "4px"}} onClick={closeReport}>
            <ArrowBackIos />
          </IconButton>
            <Breadcrumbs aria-label="breadcrumb" className="tw-text-gray1"
              sx={{color: "#343535"}} 
              maxItems={ mdBreakAfter ? MAX_ITEMS_AFTER : MAX_ITEMS_BEFORE }>
                <Link component="a" onClick={closeReport}>Home</Link>
                <Link component="a">{currentReport.ReportName}</Link>
            </Breadcrumbs>
          </div>
      </div>
    );
  }

  useEffect(() => {
    setReportDisplay(reportFound);
  },[reportFound])

  useEffect(() => {  

      ReactDOM.render(<ReportCards reportDefs={filteredReportDefs} showReport={showReport}  />,
      document.querySelector('#CardGrid'));
      
  }, [filteredReportDefs, showReport]);

  useEffect(()=>{

    if(filterExpression.length > 0) {
      const filteredDashes = reportDefs.filter(x => x.ReportName.toUpperCase().includes(filterExpression.toUpperCase()))
      setFilteredReportDefs(filteredDashes);
    } else {
      setFilteredReportDefs(reportDefs);
    }

  }, [filterExpression, reportDefs]);

  const sanitiseReportName = (reportname:string) => {
     //replace any characters that aren't a-z A-Z 0-9 or space.
     reportname = reportname.replace(/[^\w\s\d]/gi, '');
     //replace multiple spaces with a single space
     reportname = reportname.replace(/\s\s+/g, ' ');
     //replace single spaces with a hyphen
     return reportname.toLowerCase().replace(/\s/g,'-')
   }

   const checkReportPath = (reportData:IReportData[]) => {
    if(reportPath.length > 0) {
      reportData.forEach((report) => {
        const reportPathName = sanitiseReportName(report.ReportName);
  
        if(reportPath===reportPathName) {
          showReport(report);
          setReportFound(true);
          return;
        }
      });
    }
  }

  if(!loading && reportDefs.length === 0 && !dashCallMade) {
    setLoading(true);
    getReportDefs().then(()=>{
      setLoading(false);
    });
  }

  const styles:any = {
    root: {
      // input label when focused
      "& label.Mui-focused": {
        color: "#000632"
      },
      // focused color for input with variant='filled'
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#000632"
      }
    }
  };

  return (
    <>
      {reportDisplay &&
        <>
          {renderBreadcrumbNav()}
          <Page>
            <Dashboard dash={currentReport} />
          </Page>
        </>
      }

      {(!reportDisplay && noDashes) &&
        <div className="tw-h-screen">
          <div className= "tw-h-4/6 tw-grid tw-w-full tw-place-content-center">
            <div>No Reports Returned</div>
          </div>
        </div>
      }

      {(!reportDisplay && loading && !noDashes) &&
        <div className="tw-h-screen">
          <div className= "tw-h-4/6 tw-grid tw-w-full tw-place-content-center">
              <SpinnerContainer />
            </div>
        </div>
      }

      {!reportDisplay &&
        <Page>
          <div className="tw-pr-7 tw-w-full tw-grid tw-justify-items-end">
            <FormControl variant="filled" sx={styles.root}>
              <InputLabel htmlFor="filled-adorned-filter">Filter dashboards</InputLabel>
              <FilledInput
                id="filled-adorned-filter"
                type='text'
                onChange={(e) => { setFilterExpression(e.target.value) }}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label="clear"
                      className="tw-text-gray-light tw-relative"
                      style={{padding: "4px"}}
                      onClick={() => {setFilterExpression('')}}
                      >
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                }
                value={filterExpression}
              />
            </FormControl>
          </div>          
          <Box sx={{display: "flex", gap: 2, flexWrap:"wrap" }} className="tw-pl-8 tw-pt-6" id="CardGrid">
          </Box>
        </Page>
      }
    </>
  );
  
}

export default Reports;