import React, { ReactNode } from "react";

interface Props {
    children: ReactNode
}

export const Page: React.FC<Props> = (props: Props) => {
    return (
      <div style={{overflow: "auto", maxHeight: "calc(100vh - 60px)"}}>
        <div className="tw-w-full tw-content-center tw-items-center tw-p-2 tw-mx-auto">
          {props.children}
        </div>
      </div>
    );
  }