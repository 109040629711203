const configs = require('./matrix-config').configs;
const config = configs.builds.filter((c: { serverUrl: string; }) => c.serverUrl === window.location.origin )[0];

export class MatrixSettings {
  constructor() {
    const _apiUrl = config.apiUrl ? config.apiUrl : config.serverUrl;

    this.apiUrl = _apiUrl;
    this.matrixServerUrl = `${_apiUrl}/api/v1/`;
    
    console.warn("Current API URL:" + this.matrixServerUrl);
    this.readonly = true;
    this.authenticationMode = "MATRIX";
    this.reportEnv = config.reportenv;
    this.awsUrl = (this.reportEnv === "prd") ?  "https://api.bi-prd.matrixbooking.com" : `https://api.${this.reportEnv}.bi-npd.matrixbooking.com`;
    console.warn("Current AWS URL:" + this.awsUrl);

  }
  apiUrl:string
  matrixServerUrl: string;
  awsUrl: string
  readonly: boolean;
  authenticationMode: string;
  reportEnv: string;
}