const configs = {
	"builds": [
		{
			"version": "1.0.0",
			"displayName": "Matrix BI - Local Instance",
			"description": "In-development build of Matrix BI Platform",
			"serverUrl": "https://my.qsapp.net:9001",
			"outputDir":"build",
			"apiUrl": "https://dev.matrixbooking.com",
			"reportenv": "dev"
		},
		{
			"version": "1.0.0",
			"displayName": "Matrix BI",
			"description": "No-nonsense resource booking BI from Matrix Booking.",
			"serverUrl": "https://insight.matrixbooking.com",
			"outputDir":"build",
			"apiUrl": "https://app.matrixbooking.com",
			"reportenv": "prd"
		},
		{
			"version": "1.0.0",
			"displayName": "Matrix BI",
			"description": "No-nonsense resource booking BI from Matrix Booking.",
			"serverUrl": "https://bi-prd.matrixbooking.com",
			"outputDir":"build",
			"apiUrl": "https://app.matrixbooking.com",
			"reportenv": "prd"
		},
		{
			"version": "1.0.0",
			"displayName": "Matrix BI - Dev",
			"description": "Matrix BI Platform - Development",
			"serverUrl": "https://app.dev.bi-npd.matrixbooking.com",
			"outputDir":"build",
			"apiUrl": "https://dev.matrixbooking.com",
			"reportenv": "dev"
		},
		{
			"version": "1.0.0",
			"displayName": "Matrix BI - Int",
			"description": "Matrix BI Platform - Integration",
			"serverUrl": "https://app.int.bi-npd.matrixbooking.com",
			"outputDir":"build",
			"apiUrl": "https://int.matrixbooking.com",
			"reportenv": "int"
		}
		,
		{
			"version": "1.0.0",
			"displayName": "Matrix BI - Beta",
			"description": "Matrix BI Platform - Integration",
			"serverUrl": "https://app.beta.bi-npd.matrixbooking.com",
			"outputDir":"build",
			"apiUrl": "https://beta.matrixbooking.com",
			"reportenv": "beta"
		}
	]
};
module.exports = { configs };