import { createTheme } from "@mui/material/styles";

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
    }
}

export const theme = createTheme({ 
    typography: {
        fontFamily: [
          'Roboto'
        ].join(','),
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 640,
            md: 1024,
            lg: 1470,
            xl: 1800
        }
    },
    components: {
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    fontSize: "16px",
                    display: "flex"
                },
                separator: {
                    color: "rgb(52,53,53,0.6)" //#343535
                },
                li: {
                    a: {
                        color: "#000632",
                        cursor: "pointer",
                        textDecoration: "none",
                        "&:hover": {
                            textDecoration: "underline",
                            textDecorationColor: "#000632"
                        }
                    },
                    "&:last-child": {
                        a : {
                            color: "rgb(52,53,53,0.6)", //#343535
                            cursor: "auto",
                            "&:hover": {
                                textDecoration: "none"
                            }
                        }
                    }
                }
            }
        }
    }
});

