import { CircularProgress } from '@mui/material';
import React from 'react';

interface Props {}
export const Spinner: React.FC<Props> = (props: Props) => {
    return (

        <div className="tw-relative">
            <CircularProgress
                className="tw-text-gray-cloud"
                size={50}
                value={100}
                variant="determinate"
            />
            <CircularProgress
                className="tw-text-charcoal tw-absolute tw-left-0"
                size={50}
            />
        </div>
    );
}