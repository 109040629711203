import React from 'react';
import { Spinner } from '../Spinner';

interface Props {}
export const SpinnerContainer: React.FC<Props> = (props: Props) => {
    return (
        <div className="tw-bg-white tw-p-4 tw-rounded-3xl">
            <Spinner />
        </div>
    );
}