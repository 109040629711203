import { IAuthMethod, ICredentials, ILogin } from "../shared/interfaces";
import React, { MouseEvent, useCallback, useEffect, useState } from "react";

import { MatrixService } from "../services/matrixservice";

const matrixService = new MatrixService();

const logoStyle = {
    borderBottom: "1px solid rgb(204, 204, 204)"
};

const alertStyle = {
    backgroundImage: "url('./assets/error.svg')",
    backgroundPosition: "12px 18px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "18px 18px"
};

const imgStyle = {
    margin: "auto"
};

const textCenterStyle = {
    margin: "auto",
    textAlign: "center" as const
};

const logintoStyle = {
    left: "-9999px"
};

const textInputStyle = {
    height: "34px",
    padding: "6px 12px"
};

const showButtonStyle = {
    width: "60px",
    height: "34px",
    padding: "4px 12px"
};

const isEmailValid = (email: string): boolean =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

const navResetPassword = (): void => {
    window.location.assign("https://app.matrixbooking.com/ui/#/reset-password");
};

const navSignupEmail = (): void => {
    window.location.assign("https://app.matrixbooking.com/ui/#/signup");
};

const Login: React.FC<{
    authMethods?: Array<IAuthMethod>;
}> = ({ authMethods: propAuthMethods }) => {
    const [loaded, setIsLoaded] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [inputType, setInputType] = useState<string>("password");
    const [authMethods, setAuthMethods] = useState<IAuthMethod[]>(
        propAuthMethods || []
    );
    const [ssoAuths, setSsoAuths] = useState<JSX.Element[]>([]);
    const [pwdVisible, setPwdVisible] = useState<boolean>(true);
    const [showPassword, setShowPassword] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [domainChecked, setDomainChecked] = useState(false);

    const toggleShowPassword = (): void => {
        const newValue = !showPassword;
        setShowPassword(newValue);
        setInputType(newValue ? "text" : "password");
    };

    const parseAuthId = (evId:any) => {
        return !isNaN(parseFloat(evId)) &&
            !isNaN(parseInt(evId) - 0)
                ? parseInt(evId)
                : undefined;

    }

    const doLogin = useCallback((login:ILogin) => {
        const isError = (data: any): data is Error => {

            if((data as Error).message) {
                return true;
            }
                        
            return false;
        }
        
        try {
            if(!isEmailValid(email)) {
                throw new Error("Invalid email address")

            }

            if(password.length === 0)
            {
                throw new Error("Please enter a password")
            }

            if(window.location.href !== `${window.location.protocol}//${window.location.hostname}`)
            {
                login.relayState = window.location.href;
            }

            matrixService.login(login).then(
                //authorised
                (data) => {

                    if(typeof data != 'boolean') {
                        data = data.response.data;
                    }
    
                    try {
    
                        if(isError(data)){
                            throw new Error(data.message);
                        }

                        window.location.href = login.relayState && login.relayState.length > 0 ? login.relayState :  "./";
                        
                    } catch (error: any) {
    
                        console.log(error);
    
                        setIsLoaded(true);
                        setShowPassword(false);
                        setProcessing(false);
                        setError(new Error(error.message));
                        
                    }
    
                    
                },
                //error
                (error) => {
    
                    let loginErr = new Error(error.message)
    
                    setIsLoaded(true);
                    setShowPassword(false);
                    setProcessing(false);
                    setError(loginErr);
                }
            );
            
        } catch (error: any) {
            console.log(error);

            setIsLoaded(true);
            setShowPassword(false);
            setProcessing(false);
            setError(new Error(error.message));
        }
        
    },[email, password.length]);

    const authenticate = (ev: MouseEvent<HTMLButtonElement>): void => {
        const _credentials: ICredentials = {
            username: email,
            password: password
        };

        const authId: number | undefined = parseAuthId(ev.currentTarget.id);

        _credentials.authid = authId;

        const _login: ILogin = {
          credentials: _credentials,
        }

        doLogin(_login);
    };

    const formSubmit = (e:any) => {
        e.preventDefault();
    }

    const createSsoButton = (a: IAuthMethod): JSX.Element => {
        return (
            <button
                type="submit"
                className="tw-px-3 tw-py-0.5 tw-w-full tw-border tw-border-solid tw-rounded-md active:tw-underline focus:tw-underline focus:tw-outline-none tw-text-white tw-bg-midnight-blue tw-border-midnight-blue
                hover:tw-text-white hover:tw-bg-midnight-blue hover:tw-border-midnight-blue
                focus:tw-text-white focus:tw-bg-midnight-blue focus:tw-border-midnight-blue tw-mt-2"
                id={a.id.toString()}
                onClick={() => window.location.assign(matrixService.getAuthURL(email, a.id))}
            >
                Login with&nbsp;{a.title}
            </button>
        );
    };

    const defineSsoAuths = (): Array<JSX.Element> => {
        const authButtons: Array<JSX.Element> = [];
        if (authMethods.some((a) => a.type === "SAML")) {
            const ssoItems = authMethods.filter((a) => a.type === "SAML");

            ssoItems.forEach((a) => {
                authButtons.push(createSsoButton(a));
            });
        }
        return authButtons;
    };

    

    useEffect(() => {
        setIsLoaded(true);
        setShowPassword(false);
        setProcessing(false);
    }, []);


    useEffect(() => {
        setAuthMethods(propAuthMethods || []);
    }, [propAuthMethods]);

    useEffect(() => {
        if (isEmailValid(email)) {
            console.log("Valid Email");

            if(!domainChecked) {
                matrixService.getAuthMethods(email).then(
                    (result: any[]) => {
                        if (result.length > 0) {
                            console.log("Auths received", JSON.stringify(result));

                            let _auths: IAuthMethod[] = [];

                            result.forEach((a) => {
                                const _auth: IAuthMethod = {
                                    id: a.id,
                                    title: a.title,
                                    type: a.type
                                };

                                _auths.push(_auth);
                            });

                            setAuthMethods(_auths);
                            setDomainChecked(true);
                        }
                    },
                    //error
                    () => {
                        setIsLoaded(true);
                        setShowPassword(false);
                        setProcessing(false);
                        setError(error);
                    }
                );
            }
        }
        else {
            setDomainChecked(false);
        }
    }, [email, domainChecked, error]);

    useEffect(() => {
        setSsoAuths(defineSsoAuths());
        setPwdVisible(
            authMethods.length === 0 ||
                authMethods.some((a) => a.type === "MATRIX")
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authMethods]);

    if (loaded) {
        return (
        <form id="confirm" onSubmit={formSubmit} className="tw-h-full tw-flex tw-flex-col tw-items-center tw-pt-20 sm:tw-pt-15vh">
            <div className="tw-flex tw-flex-col tw-items-left tw-w-300
                    sm:tw-w-400 tw-p-4  tw-pb-5 sm:tw-p-6 sm:tw-pb-7
                    tw-bg-white tw-border tw-border-solid tw-border-gray-cerebral tw-rounded-xl">

                <div className="tw-w-full tw-pb-2 sm:tw-pb-3 tw-items-center" style={logoStyle}>
                    <img src="./assets/mb-logo-black.svg" alt="Matrix Booking" className="tw-w-42 sm:tw-w-34" style={imgStyle} />
                    <div className="tw-font-bold tw-w-full" style={textCenterStyle}>Insight</div>
                </div>
                {(error) && (
                    <div className="tw-bg-light-pink tw-p-2 tw-rounded-md tw-pl-7 tw-text-md tw-mt-3"
                    style={alertStyle}>
                        <h1 className="tw-m-0 tw-text-xlarge tw-font-semibold">
                            Unable to log in
                        </h1>
                        <p className="tw-m-0 tw-text-charcoal tw-text-basis">
                            {error.message}
                        </p>
                    </div>
                )}
                <div className="tw-mt-3 tw-mb-2">
                    <label htmlFor="loginEmail" className="tw-block tw-mb-1 tw-text-black tw-dark:text-black tw-font-medium">
                        Email
                    </label>

                    <input
                        type="text"
                        id="usernameField"
                        className="tw-appearance-none tw-w-full 
                        tw-outline-none tw-rounded-lg tw-text-gray-900
                        tw-border tw-border-solid tw-border-gray-light"
                        style={textInputStyle}
                        spellCheck="false"
                        name="email"
                        value={email}
                        onChange={(e): void => {
                            setEmail(e.target.value);
                        }}
                        data-focus
                    />
                </div>
                {pwdVisible && (
                    <div
                        className="tw-mb-1"
                        ng-if="showPasswordLogin"
                    >
                        <label htmlFor="react-password" className="tw-block tw-mb-1 tw-text-black tw-dark:text-black tw-font-medium">
                            Password
                        </label>
                        <div className="tw-relative">
                            <input
                                type={inputType}
                                id="password"
                                className="tw-appearance-none tw-w-full 
                                tw-outline-none tw-rounded-lg tw-text-gray-900
                                tw-border tw-border-solid tw-border-gray-light"
                                style={textInputStyle}
                                value={password}
                                onChange={(e): void => {
                                    setPassword(e.target.value);
                                }}
                                spellCheck="false"
                            />
                            <input
                                type="checkbox"
                                id="checkbox"
                                className="tw-w-0 tw-h-0 tw-absolute"
                                onClick={toggleShowPassword}
                                style={logintoStyle}
                            />
                            {showPassword && (
                                <label
                                    htmlFor="showhide"
                                    className="tw-absolute tw-right-0 tw-bg-white tw-cursor-pointer
                                    tw-border tw-border-solid tw-border-gray-light tw-font-normal
                                    tw-text-midnight-blue tw-text-center tw-rounded-lg tw-rounded-l-none"
                                    style={showButtonStyle}
                                    onClick={toggleShowPassword}
                                >
                                    Hide
                                </label>
                            )}
                            {!showPassword && (
                                <label
                                    htmlFor="showHide"
                                    className="tw-absolute tw-right-0 tw-bg-white tw-cursor-pointer
                                    tw-border tw-border-solid tw-border-gray-light tw-font-normal
                                    tw-text-midnight-blue tw-text-center tw-rounded-lg tw-rounded-l-none"
                                    style={showButtonStyle}
                                    onClick={toggleShowPassword}
                                >
                                    Show
                                </label>
                            )}
                        </div>
                    </div>
                )}
                <div className="py-1">
                    {pwdVisible && (
                        <button
                            type="submit"
                            className="tw-px-3 tw-py-0.5 tw-w-full tw-border tw-border-solid tw-rounded-md active:tw-underline focus:tw-underline focus:tw-outline-none tw-text-white tw-bg-midnight-blue tw-border-midnight-blue
                            hover:tw-text-white hover:tw-bg-midnight-blue hover:tw-border-midnight-blue
                            focus:tw-text-white focus:tw-bg-midnight-blue focus:tw-border-midnight-blue tw-mt-2"
                            onClick={authenticate}
                        >
                            {processing && (
                                <i>
                                    <i className="fa fa-cog fa-spin"></i>
                                </i>
                            )}
                            {!processing && (
                                <span>Log in</span>
                            )}
                        </button>
                    )}
                    {ssoAuths.length > 0 && <div>{ssoAuths}</div>}
                </div>
                <button className="tw-mt-3 tw-text-midnight-blue tw-text-left" onClick={navResetPassword}>
                    Forgot your password?
                </button>

                <div className="tw-mt-1">
                    <button className="tw-text-midnight-blue" onClick={navSignupEmail}>
                        Not signed up? Sign up now
                    </button>
                </div>
            </div>
        </form>
        );
    } else {
        return <></>;
    }
};

export default Login;
