import React, {useState} from 'react';

import { Backdrop } from '@mui/material';
import { SpinnerContainer } from '../SpinnerContainer';

export const ScreenSpinner: React.FC<{}> = () => {

    const [processing, setProcessing] = useState(true);
    
    return (
        <Backdrop
            open={processing}
            onClick={(): void => {
                setProcessing(false);
            }}
            style={{
                width: "100%",
                height: "100%",
                textAlign: "center",
                color: "#999",
                backgroundColor: "rgba(#666,.5)",
                zIndex: 1000
            }}
        >       
        <SpinnerContainer />
    </Backdrop>
    );
}
