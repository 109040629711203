import * as moment from "moment";

import { IAuthMethod, ILogin, IReportData, IUser } from "../shared/interfaces";
import axios, { AxiosRequestHeaders } from "axios";

import { MatrixSettings } from "../models/matrixSettings";

const matrixSettings = new MatrixSettings();
const baseUrl = matrixSettings.matrixServerUrl;
const requestHeaders = {
  "Accept": "application/json;odata=verbose",
  "Content-Type": "application/json;odata=verbose",
};
const axiosAuthorisedOptions = { headers: requestHeaders, withCredentials: true };

export class MatrixService {

  // Create a MatrixService with a logout function that is invoked if a 401 error response is received
  constructor(logout?: () => void) {

    const logoutFunction = logout ?? this.logoutOfMatrix;

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        // If the error is a 401 and we are not explicitly checking for the existence of a session
        // then log an error and call the logout function to run the login workflow.
        if (error && error.response && error.response.status === 401) {
          if (error.response.config && error.response.config.url !== baseUrl + "user/session/check") {
            console.error("axios received error response code 401 - invoking logout()");
            logoutFunction();
          }
        }
        return Promise.reject(error);
      });
  }

  async login (login: ILogin): Promise<any> {
    return this.loginUsingMatrix(login)
  }

  async loginUsingMatrix(login: ILogin): Promise<any> {
    
    let returnValue: boolean | Error | null = null;
    login.rememberMe = true;
    let requestUrl = baseUrl + "user/login";

    await axios
      .post(requestUrl, login.credentials, axiosAuthorisedOptions)
      .then(async(response) => {
        console.log(JSON.stringify(response));
        returnValue = true;
      })
      .catch((error: Error) => {
        console.error("Error on Matrix Login: " + error);

        returnValue = error;
      });
    
      return returnValue;
  };

  async getUserToken(): Promise<string> {
    let requestUrl = baseUrl + "user/token/INSIGHT";
    let token = '';

    await axios
      .post(requestUrl, null, axiosAuthorisedOptions)
      .then(response => {
        token = response.data;
      })
      .catch(error => {
          console.error("Error on token retrieve " + error);
      });

      return token;
  }

  async getHeaders():Promise<AxiosRequestHeaders> {

    const _jwt = await this.getUserToken();

    return {
      'Content-Type': 'application/json',
      'authorizationToken': _jwt
    }
  }

  async getReportData():Promise<IReportData[]> {
    let requestUrl = matrixSettings.awsUrl + "/get-reports-by-account/";
    
    const _axiosHeaders = await this.getHeaders();
    
    const response = await axios
      .get(requestUrl, { "headers": _axiosHeaders })

    return response.data;
  }

  async logoutOfMatrix(): Promise<void> {
    let requestUrl = baseUrl + "user/logout";

    await axios
      .get(requestUrl, axiosAuthorisedOptions)
      .then(response => {
        console.log("Logged Out:" + JSON.stringify(response));
      })
      .catch(error => {
        console.error("Error on Matrix logout: " + error);
      });
  }

  async userSessionCheck(): Promise<IUser | null> {
    var requestUrl = baseUrl + "user/session/check";

    await axios
      .get(requestUrl, axiosAuthorisedOptions)
      .then(response => {
        console.log("Successful authentication via session check:" + JSON.stringify(response));

      })
      .catch(error => {
        console.log("Session not authenticated: " + error);
      });

    return await this.getCurrentUser();
  }

  async getCurrentUser(): Promise<IUser | null> {
    var requestUrl = baseUrl + "user/current?include=defaults";
    let _user: IUser | null = null;

    await axios
      .get(requestUrl, axiosAuthorisedOptions)
      .then(response => {
        console.log("User Received: " + JSON.stringify(response));
        _user = JSON.parse(JSON.stringify(response.data));
      })
      .catch(error => {
        console.error("Error on User Retrieval: " + error);
      });

    return _user;
  };

  async getAuthMethods(username: string): Promise<any> {
    const requestUrl = baseUrl + "user/login/domainCheck";
    let authMethods: IAuthMethod[] = [];
    await axios
      .post(requestUrl, { username: username }, { headers: requestHeaders })
      .then(response => {
        authMethods = response.data.authentications;
      })
      .catch(error => {
        console.error("domainCheck", error);
      });

    return authMethods;
  };

  getAuthURL(username: string, authid: number):string {
    return `${baseUrl}authenticationMethod/${authid}/redirect?username=${username}&RelayState=${window.location.href}`;
  };

  momentToString(d: moment.Moment): string {
    return d.format("YYYY-MM-DD[T]HH:mm");
  }
}
