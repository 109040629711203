import { Card, CardContent, CardMedia, Typography } from '@mui/material';

import { IReportData } from '../shared/interfaces';
import React from 'react'

const ReportCards: React.FC<{reportDefs: IReportData[], showReport: (reportDef: IReportData) => void}> = ({ reportDefs, showReport }) => {

  const createReportCard = (reportDef:IReportData) => {

    const imageName = reportDef.ImageFile.length ===0 ? "no-thumbnail" : reportDef.ImageFile;

    return (
          <Card sx={{ 
              width: {
                  xs: "98%", 
                  md: "47%",
                  lg: "32%"
                }, 
                minWidth: { xs:327, sm: "32%"}, 
                minHeight:{ xs:260, sm: "32%"},  
                cursor: "pointer" 
              }} 
              onClick={() => showReport(reportDef)}
            >
            <CardContent>
              <Typography sx={{ fontSize: 18 }}
                className="tw-mb-1 tw-font-medium tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
                {reportDef.ReportName}
              </Typography>
              <CardMedia
                component="img"
                image={"/assets/reports/" + imageName + ".png"}
                alt="Image"
                sx={{ objectFit: "contain" }}
              />
            </CardContent>
          </Card>

    )
  }
  
  const createCards = (): Array<JSX.Element> => {

    const cards: Array<JSX.Element> = [];

    for(let i = 0; i < reportDefs.length; i++)
    {
      let reportDef = reportDefs[i];
      cards.push(createReportCard(reportDef));
    }

    return cards;
    
  };

  return (
    <>
      {createCards()}
    </>
  );
  
}

export default ReportCards;